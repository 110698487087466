.grid-bg {
    --color: var(--mantine-color-gray-1);
    --width: 5%;
    --scale: 20px;
    background-image: linear-gradient(var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 100%),
        linear-gradient(90deg, var(--color) 0%, var(--color) var(--width), transparent var(--width), transparent 100%);
    background-size: var(--scale) var(--scale);
    background-position: calc(var(--scale) / 2) calc(var(--scale) / 2);
}

.grid-bg-anim {
    animation: sliding-grid-anim 2s linear infinite;
}

@keyframes sliding-grid-anim {
    0% {
        background-position-x: 0px;
        background-position-y: 0px;
    }

    100% {
        background-position-x: calc(var(--scale));
        background-position-y: calc(var(--scale));
    }
}

.dots-bg {
    --color: var(--mantine-color-gray-1);
    --size: 10%;
    --scale: 30px;
    /* background-image: radial-gradient(white, var(--color)); */
    background-image: radial-gradient(var(--color) 0%, var(--color) var(--size), transparent var(--size), transparent 100%);
    background-size: var(--scale) var(--scale);
}

.sliding-color-bg {
    --width: 120px;
    --main-color: white;
    --color-1: var(--mantine-color-pink-6);
    --color-2: var(--mantine-color-yellow-5);
    background-image: linear-gradient(45deg,
            transparent 0%,
            transparent 40%,
            var(--color-1) 40%,
            var(--color-1) 50%,
            var(--color-2) 50%,
            var(--color-2) 60%,
            transparent 60%,
            transparent 100%);
    background-color: var(--main-color);
    background-repeat: no-repeat;
    background-position-x: calc(-1 * var(--width));
    /* transition: background-position 1s linear; */
    animation: sliding-bg-anim 2.6s linear infinite;
}

@keyframes sliding-bg-anim {
    0% {
        background-position-x: calc(-1 * var(--width));
    }

    50% {
        background-position-x: var(--width);
    }

    100% {
        background-position-x: var(--width);
    }
}

.cta-bg {
    background-image:
        radial-gradient(circle at 70% 0%, var(--mantine-color-dark-9) 0%, transparent 30%),
        radial-gradient(circle at 80% 120%, var(--mantine-color-primary-3) 0%, transparent 50%),
        radial-gradient(circle at 100% 0%, var(--mantine-color-pink-2) 0%, transparent 30%),
        radial-gradient(ellipse at 60% 130%, var(--mantine-color-primary-3) 0%, transparent 50%),
        radial-gradient(ellipse at 40% 130%, var(--mantine-color-primary-3) 0%, transparent 50%);
}
*, ::before, ::after {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(51 154 240 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
}
::backdrop {
        --tw-border-spacing-x: 0;
        --tw-border-spacing-y: 0;
        --tw-translate-x: 0;
        --tw-translate-y: 0;
        --tw-rotate: 0;
        --tw-skew-x: 0;
        --tw-skew-y: 0;
        --tw-scale-x: 1;
        --tw-scale-y: 1;
        --tw-pan-x:  ;
        --tw-pan-y:  ;
        --tw-pinch-zoom:  ;
        --tw-scroll-snap-strictness: proximity;
        --tw-gradient-from-position:  ;
        --tw-gradient-via-position:  ;
        --tw-gradient-to-position:  ;
        --tw-ordinal:  ;
        --tw-slashed-zero:  ;
        --tw-numeric-figure:  ;
        --tw-numeric-spacing:  ;
        --tw-numeric-fraction:  ;
        --tw-ring-inset:  ;
        --tw-ring-offset-width: 0px;
        --tw-ring-offset-color: #fff;
        --tw-ring-color: rgb(51 154 240 / 0.5);
        --tw-ring-offset-shadow: 0 0 #0000;
        --tw-ring-shadow: 0 0 #0000;
        --tw-shadow: 0 0 #0000;
        --tw-shadow-colored: 0 0 #0000;
        --tw-blur:  ;
        --tw-brightness:  ;
        --tw-contrast:  ;
        --tw-grayscale:  ;
        --tw-hue-rotate:  ;
        --tw-invert:  ;
        --tw-saturate:  ;
        --tw-sepia:  ;
        --tw-drop-shadow:  ;
        --tw-backdrop-blur:  ;
        --tw-backdrop-brightness:  ;
        --tw-backdrop-contrast:  ;
        --tw-backdrop-grayscale:  ;
        --tw-backdrop-hue-rotate:  ;
        --tw-backdrop-invert:  ;
        --tw-backdrop-opacity:  ;
        --tw-backdrop-saturate:  ;
        --tw-backdrop-sepia:  ;
}
.pointer-events-none {
        pointer-events: none;
}
.visible {
        visibility: visible;
}
.absolute {
        position: absolute;
}
.relative {
        position: relative;
}
.sticky {
        position: sticky;
}
.bottom-full {
        bottom: 100%;
}
.left-0 {
        left: 0px;
}
.top-0 {
        top: 0px;
}
.z-10 {
        z-index: 10;
}
.z-50 {
        z-index: 50;
}
.ml-12 {
        margin-left: 3rem;
}
.mt-lg {
        margin-top: 1.25rem;
}
.mt-xs {
        margin-top: 0.625rem;
}
.block {
        display: block;
}
.inline-block {
        display: inline-block;
}
.flex {
        display: flex;
}
.hidden {
        display: none;
}
.aspect-\[4\/3\] {
        aspect-ratio: 4/3;
}
.h-80 {
        height: 20rem;
}
.h-auto {
        height: auto;
}
.min-h-screen {
        min-height: 100vh;
}
.w-32 {
        width: 8rem;
}
.w-4 {
        width: 1rem;
}
.w-full {
        width: 100%;
}
.max-w-xs {
        max-width: 20rem;
}
.flex-1 {
        flex: 1 1 0%;
}
.grow {
        flex-grow: 1;
}
.scale-105 {
        --tw-scale-x: 1.05;
        --tw-scale-y: 1.05;
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.transform {
        transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
.cursor-pointer {
        cursor: pointer;
}
.flex-col {
        flex-direction: column;
}
.items-end {
        align-items: flex-end;
}
.justify-end {
        justify-content: flex-end;
}
.overflow-hidden {
        overflow: hidden;
}
.whitespace-normal {
        white-space: normal;
}
.rounded-lg {
        border-radius: 1rem;
}
.rounded-md {
        border-radius: 0.5rem;
}
.rounded-none {
        border-radius: 0px;
}
.rounded-sm {
        border-radius: 0.25rem;
}
.border {
        border-width: 1px;
}
.border-none {
        border-style: none;
}
.bg-dark {
        --tw-bg-opacity: 1;
        background-color: rgb(37 38 43 / var(--tw-bg-opacity));
}
.bg-primary {
        --tw-bg-opacity: 1;
        background-color: rgb(121 80 242 / var(--tw-bg-opacity));
}
.bg-transparent {
        background-color: transparent;
}
.bg-clip-text {
        -webkit-background-clip: text;
                background-clip: text;
}
.object-cover {
        -o-object-fit: cover;
           object-fit: cover;
}
.p-10 {
        padding: 2.5rem;
}
.p-sm {
        padding: 0.75rem;
}
.px-sm {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
}
.py-md {
        padding-top: 1rem;
        padding-bottom: 1rem;
}
.py-xl {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
}
.py-xs {
        padding-top: 0.625rem;
        padding-bottom: 0.625rem;
}
.pl-sm {
        padding-left: 0.75rem;
}
.text-center {
        text-align: center;
}
.text-3xl {
        font-size: 1.875rem;
        line-height: 2.25rem;
}
.text-\[0\.9em\] {
        font-size: 0.9em;
}
.text-sm {
        font-size: 0.875rem;
}
.text-xl {
        font-size: 1.25rem;
}
.font-bold {
        font-weight: 700;
}
.uppercase {
        text-transform: uppercase;
}
.text-gray-300 {
        --tw-text-opacity: 1;
        color: rgb(222 226 230 / var(--tw-text-opacity));
}
.text-primary {
        --tw-text-opacity: 1;
        color: rgb(121 80 242 / var(--tw-text-opacity));
}
.text-primary-100 {
        --tw-text-opacity: 1;
        color: rgb(229 219 255 / var(--tw-text-opacity));
}
.text-primary-200 {
        --tw-text-opacity: 1;
        color: rgb(208 191 255 / var(--tw-text-opacity));
}
.text-primary-300 {
        --tw-text-opacity: 1;
        color: rgb(177 151 252 / var(--tw-text-opacity));
}
.text-white {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
}
.shadow {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.shadow-sm {
        --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
        --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
        box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.outline {
        outline-style: solid;
}
.blur {
        --tw-blur: blur(8px);
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.drop-shadow-lg {
        --tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1));
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.filter {
        filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}
.transition {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.transition-colors {
        transition-property: color, background-color, border-color, text-decoration-color, fill, stroke;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 150ms;
}
.base-border {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }

html {
    font-size: 20px;
    min-height: 100vh;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: var(--mantine-font-family);
    scroll-behavior: smooth;
    scroll-padding-top: 4rem;
}

a {
    color: inherit;
    text-decoration: none;
}

p {
    margin: 0;
}

.rubik {
    font-family: 'Rubik', sans-serif !important;
}

.ofv {
    overflow: visible !important;
}

.nosel {
    -webkit-user-select: none !important;
       -moz-user-select: none !important;
            user-select: none !important;
}

.flg {
    flex-grow: 1;
}

.mantine-Switch-track {
    cursor: pointer;
}

/* @media (prefers-color-scheme: dark) {
    html {
        color-scheme: dark;
    }

    body {
        color: white;
        background: black;
    }
} */

/* .hoverScale {
    transform: scale(1.01);
} */

.even\:self-end:nth-child(even) {
        align-self: flex-end;
}

.hover\:bg-gray-50:hover {
        --tw-bg-opacity: 1;
        background-color: rgb(248 249 250 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
        --tw-text-opacity: 1;
        color: rgb(121 80 242 / var(--tw-text-opacity));
}

.hover\:text-white:hover {
        --tw-text-opacity: 1;
        color: rgb(255 255 255 / var(--tw-text-opacity));
}

@media not all and (min-width: 640px) {
        .max-sm\:\!border-x-0 {
                border-left-width: 0px !important;
                border-right-width: 0px !important;
        }
}

@media (min-width: 640px) {
        .sm\:m-0 {
                margin: 0px;
        }
        .sm\:mt-\[-2\.5rem\] {
                margin-top: -2.5rem;
        }
        .sm\:block {
                display: block;
        }
        .sm\:hidden {
                display: none;
        }
        .sm\:h-60 {
                height: 15rem;
        }
        .sm\:w-3\/5 {
                width: 60%;
        }
        .sm\:w-4\/5 {
                width: 80%;
        }
        .sm\:w-6 {
                width: 1.5rem;
        }
        .sm\:rounded-md {
                border-radius: 0.5rem;
        }
        .sm\:p-lg {
                padding: 1.25rem;
        }
        .sm\:px-16 {
                padding-left: 4rem;
                padding-right: 4rem;
        }
        .sm\:px-xl {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
        }
        .sm\:py-8 {
                padding-top: 2rem;
                padding-bottom: 2rem;
        }
        .sm\:text-\[1em\] {
                font-size: 1em;
        }
        .sm\:shadow-xs {
                --tw-shadow: 0 2px 3px -1px rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
                --tw-shadow-colored: 0 2px 3px -1px var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
        }
    .sm\:base-border {
        border: 0.0625rem solid var(--mantine-color-gray-3);
    }
}

@media (min-width: 768px) {
        .md\:top-\[30px\] {
                top: 30px;
        }
        .md\:ml-\[-30px\] {
                margin-left: -30px;
        }
        .md\:block {
                display: block;
        }
        .md\:hidden {
                display: none;
        }
        .md\:w-56 {
                width: 14rem;
        }
        .md\:flex-1 {
                flex: 1 1 0%;
        }
}

@media (min-width: 1024px) {
        .lg\:absolute {
                position: absolute;
        }
        .lg\:right-5 {
                right: 1.25rem;
        }
        .lg\:top-5 {
                top: 1.25rem;
        }
        .lg\:mt-0 {
                margin-top: 0px;
        }
        .lg\:block {
                display: block;
        }
        .lg\:hidden {
                display: none;
        }
}
